(function(window) { var svs = window.svs || {}; svs.modules = svs.modules || []; if(svs.modules.indexOf('/modules/oddset-kambi/components/sportradar-statistics/assets/javascripts/sportradar-statistics.js') >= 0) return;  svs.modules.push('/modules/oddset-kambi/components/sportradar-statistics/assets/javascripts/sportradar-statistics.js');
"use strict";


const {
  useEffect,
  useRef,
  useState
} = React;
const Icon = svs.ui.ReactIcon;
const IconButton = svs.ui.ReactIconButton;
const {
  Modal
} = svs.components.lbUi.modal;
const {
  Loader
} = svs.components.ReactLoader;
const {
  sportradarMatchUrl
} = svs.oddset_kambi.data;
const SportradarStatisticsIframe = _ref => {
  let {
    betradarId,
    closeModal
  } = _ref;
  const iframeSectionRef = useRef(null);
  const [isLoading, setIsLoading] = useState(true);
  useEffect(() => {
    const handleClickOutside = event => {
      if (iframeSectionRef.current && !iframeSectionRef.current.contains(event.target)) {
        closeModal();
      }
    };
    let listenerAdded = false;
    if (betradarId) {
      document.addEventListener('mousedown', handleClickOutside);
      listenerAdded = true;
    }
    return () => {
      if (listenerAdded) {
        document.removeEventListener('mousedown', handleClickOutside);
      }
    };
  }, [betradarId, closeModal]);
  return React.createElement(Modal, {
    isOpen: Boolean(betradarId)
  }, isLoading && React.createElement("div", {
    className: "oddset-sportradar-statistics-loader"
  }, React.createElement(Loader, null)), React.createElement("div", {
    className: "oddset-sportradar-statistics-iframe-container",
    ref: iframeSectionRef
  }, React.createElement("div", {
    className: "oddset-sportradar-statistics-iframe-container__close-btn"
  }, React.createElement(IconButton, {
    "aria-label": "St\xE4ng ut\xF6kad statistik",
    onClick: closeModal
  }, React.createElement(Icon, {
    color: "fc-white",
    icon: "close",
    size: "300"
  }))), React.createElement("iframe", {
    "aria-label": "Ut\xF6kad statistik",
    onLoad: () => setIsLoading(false),
    role: "document",
    src: "".concat(sportradarMatchUrl, "/").concat(betradarId),
    title: "Ut\xF6kad statistik"
  })));
};
setGlobal('svs.oddset_kambi.components.sportradar_statistics.SportradarStatisticsIframe', SportradarStatisticsIframe);

 })(window);